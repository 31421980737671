// Bu dosya, ekip alımları sayfasını doldurmak için oluşturuldu.
// {
//     title: "",           -- Resmin altında, açıklamanın üstünde gözükecek yazı.
//     subtitle: "",        -- Resmin ve title'ın altında gözükecek, açıklama yazısı.
//     image: "",           -- Resim linki.
//     link: "",            -- Kullanıcının butona tıkladığında yönlendirileceği link.
//     button_text: "",     -- is_active 1'se buton gösterilir. Bu butonun içerisindeki yazı.
//     is_active: 1         -- 0 yaparak butonu deaktif hale getirebilirsiniz.
// }

module.exports = [

	{
		title: "Anime Çevirmeni",
		subtitle: "Sorumluluk sahibi, başladığı işi yarım bırakmayan, öğrenmeye açık, haftada birkaç saatini ayırabilecek, iletişimde kopukluk yaratmayacak, Türkçeye ve İngilizceye hakim kişileri aramızda görmek isteriz.",
		image: "https://media.tenor.com/SXSued_jB3oAAAAC/concentrate-focus.gif",
		link: "https://forms.gle/uiFDZ3pPtQrCErFH6",
		button_text: "Başvur",
		is_active: 1
	},
	
	{
		title: "Anime Redaktörü",
		subtitle: "Sorumluluk sahibi, başladığı işi yarım bırakmayan, öğrenmeye açık, haftada birkaç saatini ayırabilecek, iletişimde kopukluk yaratmayacak, Türkçeye hakim kişileri aramızda görmek isteriz.",
		image: "https://media.tenor.com/dMrlnz_BpYMAAAAC/working-hard.gif",
		link: "https://forms.gle/z62F5upWigbQm4P6A",
		button_text: "Başvur",
		is_active: 1
	}

]